import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  gridContainer,
  imageStyle,
  content,
} from './BackgroundImage.module.scss'

export const BackgroundImage = ({
  children,
  image,
  alt = '',
  backgroundColor = '',
}) => {
  return (
    <div
      className={gridContainer}
      style={{ backgroundColor: `${backgroundColor}` }}>
      {image && <GatsbyImage className={imageStyle} image={image} alt={alt} />}
      <div className={content}>{children}</div>
    </div>
  )
}
