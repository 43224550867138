import React from 'react'
import loadable from '@loadable/component'
import { BackgroundImage } from 'components/BackgroundImage'
import './Section.scss'

const HeaderSection = loadable(() => import('./HeaderSection'))

/* 
  Temp component due to section component is used throughout entire site as multiple types of components
  needs to be broken down into a smaller generic component
  see - composition vs inheritance
*/
const SectionType = ({
  pathname,
  as,
  backgroundImage,
  theme,
  fadeIn,
  children,
}) => {
  if (pathname === '/' && as === 'header') {
    return (
      <HeaderSection className='iliad__section--background'>
        {children}
      </HeaderSection>
    )
  }
  if (backgroundImage) {
    return (
      <BackgroundImage
        image={backgroundImage}
        className='iliad__section--background'
        backgroundColor={theme.backgroundColor}
        // fadeIn={fadeIn}
      >
        {children}
      </BackgroundImage>
    )
  }
  return <div className='iliad__section--background'>{children}</div>
}

export default SectionType
